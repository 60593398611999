import {
    PageHeader,
    ParagraphHeader,
    Paragraph,
    EmText,
    ExternalLink,
} from "../../MainLayout/styled";
import YoutubeEmbedResponsive from "../../Media/YoutubeEmbedResponsive";
import { MainContainer, ContentText, VideoCaption } from "../styled";
import projectImg from "./cohesive.png";
import styled from "styled-components";

const Emp = styled.span`
    font-weight: bold;
`;

const ProjectPage = () => {
    return (
        <MainContainer>
            <PageHeader>
                CoHeSIVE &mdash; Immersive Co-Design for Healthier Public Spaces
            </PageHeader>
            <YoutubeEmbedResponsive
                title="Embedded Media titled: Wander - CoHeSIVE project"
                width="560"
                height="315"
                // src="https://youtu.be/5p5pFRfTgaQ"
                videoCode="5p5pFRfTgaQ"
            >
                <img src={projectImg} alt="project video CoHeSIVE" />
            </YoutubeEmbedResponsive>
            <VideoCaption>
                XR-<EmText>Project</EmText>
                <br />
                <b>
                    CoHeSIVE &mdash; Immersive Co-Design for Healthier Public
                    Spaces
                </b>
            </VideoCaption>

            <ContentText>
                <ParagraphHeader>Challenge</ParagraphHeader>
                <Paragraph>
                    As cities grow denser to accommodate rising populations,
                    urban public spaces must be designed to balance
                    sustainability, livability, and health. However, traditional
                    urban planning methods often struggle to engage citizens
                    effectively in the design process. Paper maps, 2D sketches,
                    and even 3D models can be difficult for non-experts to
                    interpret, making it challenging for stakeholders to
                    visualize and assess the impact of design choices on their
                    environment.
                </Paragraph>
                <Paragraph>The challenge, then, is twofold:</Paragraph>
                <Paragraph as="ol" $keepTogether>
                    <li>
                        <Emp>Enhancing public participation</Emp> in urban
                        planning to create spaces that genuinely reflect
                        community needs.
                    </li>
                    <li>
                        <Emp>
                            Making future urban environments more tangible
                        </Emp>{" "}
                        tangible for citizens, allowing them to experience and
                        influence their design in meaningful ways.
                    </li>
                </Paragraph>

                <ParagraphHeader>Objective</ParagraphHeader>
                <Paragraph>
                    This project aimed to develop and evaluate CoHeSIVE
                    (Co-designing Healthy Public Spaces through Immersive
                    Virtual Environments), a Virtual Reality (VR) application
                    that enables participants to experience and shape future
                    urban spaces. The project focused on redesigning the central
                    station plaza in Eindhoven, a rapidly densifying area, to
                    explore how immersive VR can improve participatory urban
                    planning.
                </Paragraph>

                <ParagraphHeader>The Result</ParagraphHeader>
                <Paragraph>
                    A unique aspect of CoHeSIVE is that the application itself
                    was developed collaboratively through an iterative process,
                    incorporating direct feedback from citizens, urban planners,
                    and researchers. The WANDER XR Experience Lab led the
                    technical development. To enhance the scientific value of
                    the results, several methods of user data tracking were
                    integrated.
                </Paragraph>
                <Paragraph>
                    The application allows users to interactively explore
                    different design attributes, such as:
                </Paragraph>
                <Paragraph as="ul" $keepTogether>
                    <li>
                        <Emp>Tree coverage</Emp> (from no trees to dense
                        greenery)
                    </li>
                    <li>
                        <Emp>Seating arrangements</Emp> (few to many benches)
                    </li>
                    <li>
                        <Emp>Grass areas</Emp> (small to large)
                    </li>
                    <li>
                        <Emp>Building heights</Emp> (low-rise to skyscrapers)
                    </li>
                    <li>
                        <Emp>Lighting</Emp> (more or fewer lampposts)
                    </li>
                    <li>
                        <Emp>Presence of fountains</Emp>
                    </li>
                </Paragraph>
                <Paragraph>
                    Participants could experiment with different configurations,
                    experiencing the impact of their choices in real time. The
                    final version of CoHeSIVE was tested in workshops with 41
                    participants, where they designed their ideal station plaza
                    using VR. The results of these experiments were published in
                    several articles, such as{" "}
                    <ExternalLink href="https://www.sciencedirect.com/science/article/pii/S0198971524001236">
                        Experiencing the future: Evaluating a new framework for
                        the participatory co-design of healthy public spaces
                        using immersive virtual reality.
                    </ExternalLink>
                </Paragraph>

                <ParagraphHeader>
                    Insights and Future Applications
                </ParagraphHeader>
                <Paragraph>
                    The project generated several key insights:
                </Paragraph>
                <Paragraph as="ul" $keepTogether>
                    <li>
                        <Emp>VR significantly enhances participation.</Emp> 75%
                        of participants recommended using CoHeSIVE in co-design
                        processes, highlighting how it helps visualize and
                        communicate ideas more effectively than traditional
                        methods.
                    </li>
                    <li>
                        <Emp>The VR tool itself benefited from co-design.</Emp>{" "}
                        The iterative development process allowed participants
                        to{" "}
                        <Emp>
                            refine features, improve usability, and tailor the
                            tool
                        </Emp>{" "}
                        to real-world urban planning needs.
                    </li>
                    <li>
                        <Emp>
                            People's preferences varied, but common themes
                            emerged.
                        </Emp>{" "}
                        Most participants favored a{" "}
                        <Emp>green, well-lit, and socially inviting space</Emp>,
                        with many trees, several benches, and a large grass
                        area.
                    </li>
                    <li>
                        <Emp>Interactivity and immersion matter.</Emp>{" "}
                        Participants found the ability to manipulate their
                        environment and experience it first-hand much more
                        engaging than passive visualization tools.
                    </li>
                    <li>
                        <Emp>Practical challenges remain.</Emp> Some users
                        suggested adding <Emp>dynamic elements</Emp>, such as
                        people, weather changes, and real-time feedback on
                        design impacts (e.g., shade, wind, noise).
                    </li>
                </Paragraph>
                <Paragraph>
                    Looking ahead, future iterations of CoHeSIVE could integrate
                    multi-user experiences, AI-driven generative design, and
                    expanded case studies to make it an even more powerful tool
                    for participatory urban planning. Developed on this has
                    already started and a multi-user version has been delivered.
                </Paragraph>
                <Paragraph>
                    This project has demonstrated that immersive technologies
                    like VR are not just visualization tools—they are powerful
                    platforms for democratic, inclusive, and data-driven urban
                    design.
                </Paragraph>

                <ParagraphHeader>Collaborators</ParagraphHeader>
                <Paragraph>
                    This project was financed with a grant from the EWUU
                    alliance. For this project, the WANDER XR Experience Lab
                    collaborated with{" "}
                    <ExternalLink href="https://www.tue.nl/en/research/researchers/gamze-dane">
                        Gamze Dane
                    </ExternalLink>{" "}
                    from TU/e,{" "}
                    <ExternalLink href="https://www.wur.nl/nl/personen/alexander-klippel.htm">
                        prof. dr. Alexander Klippel
                    </ExternalLink>{" "}
                    from the Cultural Geography group, and{" "}
                    <ExternalLink href="https://jan-oliver-wallgruen.info/">
                        dr. ing. Jan Oliver Wallgrün
                    </ExternalLink>
                    .
                </Paragraph>
            </ContentText>
        </MainContainer>
    );
};

export default ProjectPage;
