import styled from "styled-components";
import { useState } from "react";
import {
    PageHeader,
    EmText,
    // Paragraph,
    ParagraphHeader,
} from "../MainLayout/styled";
import allProjects from "./projects";
import ProjectList from "./ProjectList";
import ProjectTile, { TileContainer } from "./ProjectTile";
import Filters from "./Filters";
import { tagsInUse } from "./projects";
import { LinkButton } from "../Buttons";

const ColumnContainer = styled.div`
    display: flex;
    gap: 32px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const Column = styled.div`
    flex: 1;
`;
const LeftColumn = styled(Column)`
    flex: 0 0 300px;
`;

const projectList = allProjects.filter((p) => !p.archived);

const Projects = () => {
    const [activeFilter, setActiveFilter] = useState(null);

    const handleFilterClick = (filter) => {
        setActiveFilter((prev) => (filter === prev ? null : filter));
    };

    return (
        <div>
            <PageHeader>
                XR-<EmText>Projects</EmText>
            </PageHeader>
            <ColumnContainer>
                <LeftColumn>
                    {/* <Paragraph>
            Donec ultrices purus vitae risus vestibulum pellentesque. Sed
            pellentesque eu leo eu placerat. Nam ac iaculis erat. Fusce arcu
            laoreet.
          </Paragraph> */}
                    <ParagraphHeader>Our projects:</ParagraphHeader>
                    <Filters
                        tags={Object.values(tagsInUse)}
                        onClickFilter={handleFilterClick}
                        activeFilter={activeFilter}
                    />
                    <ProjectList projects={projectList} />
                    <LinkButton to="/about/contact">contact us</LinkButton>
                </LeftColumn>
                <Column>
                    <TileContainer>
                        {projectList
                            .filter(
                                (project) =>
                                    !activeFilter ||
                                    project.tags?.some(
                                        (tag) => tag === activeFilter
                                    )
                            )
                            .map((project) => {
                                return (
                                    <ProjectTile
                                        project={project}
                                        key={project.label}
                                    />
                                );
                            })}
                    </TileContainer>
                </Column>
            </ColumnContainer>
        </div>
    );
};

export default Projects;
