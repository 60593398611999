import { stringToUrl } from "../../helpers/string";
import TreePollen from "../Project/ProjectPages/TreePollen";
import treePollenImg from "../Project/ProjectPages/treePollen.png";
import Metabolomics from "../Project/ProjectPages/Metabolomics";
import metabolomicsImg from "../Project/ProjectPages/metabolomics.png";
import Schokland from "../Project/ProjectPages/Schokland";
import schoklandImg from "../Project/ProjectPages/schokland.png";
import WindVisualisation from "../Project/ProjectPages/WindVisualisation";
import windVisualisationImg from "../Project/ProjectPages/windVisualisation.png";
import AuroraCanteen from "../Project/ProjectPages/AuroraCanteen";
import auroraCanteenImg from "../Project/ProjectPages/auroraCanteen.png";
import ProteinTransition from "../Project/ProjectPages/ProteinTransition";
import proteinTransitionImg from "../Project/ProjectPages/proteinTransition.jpg";
import Castor from "../Project/ProjectPages/Castor";
import castorImg from "../Project/ProjectPages/castor.jpg";
import CoHeSive from "../Project/ProjectPages/CoHeSIVE";
import coHeSiveImg from "../Project/ProjectPages/cohesive.png";
import CropViewer from "../Project/ProjectPages/CropViewer";
import cropViewerImg from "../Project/ProjectPages/cropViewer.png";
import ProteinGame from "../Project/ProjectPages/ProteinGame";
import proteinGameImg from "../Project/ProjectPages/proteinGame.jpg";
import Heartbeat from "../Project/ProjectPages/Heartbeat";
import heartbeatImg from "../Project/ProjectPages/heartbeat.jpg";
import { primaryColor, secondaryColor } from "../../style/colors";

const tags = {
    research: { label: "Research", color: primaryColor },
    education: { label: "Education", color: secondaryColor },
};
// tags: [tags.research, tags.education],

const projects = [
    {
        label: "CoHeSIVE",
        description: "Immersive co-design for healthier public spaces",
        image: coHeSiveImg,
        component: CoHeSive,
    },
    {
        label: "CASTOR",
        description: "Visualizing possible future landscapes",
        image: castorImg,
        component: Castor,
    },
    {
        label: "Tree pollen",
        description: "Unity application of WUR campus",
        image: treePollenImg,
        component: TreePollen,
    },
    {
        label: "Metabolomics",
        description: "Web-based 3D application",
        image: metabolomicsImg,
        component: Metabolomics,
        archived: true,
    },
    {
        label: "Schokland",
        description: "World Heritage Site in Mixed Reality",
        image: schoklandImg,
        component: Schokland,
    },
    {
        label: "Wind Visualisation",
        image: windVisualisationImg,
        component: WindVisualisation,
        archived: true,
    },
    {
        label: "Aurora Canteen",
        description: "Digital Twin",
        image: auroraCanteenImg,
        component: AuroraCanteen,
    },
    {
        label: "Protein Transition",
        description: "XR",
        image: proteinTransitionImg,
        component: ProteinTransition,
        archived: true,
    },
    {
        label: "Virtual Tomato",
        description: "Crop Viewer",
        image: cropViewerImg,
        component: CropViewer,
    },
    {
        label: "Protein Game",
        description: "XR for Education",
        image: proteinGameImg,
        component: ProteinGame,
    },
    {
        label: "HeARtbeat",
        description: "Integrating AR for Physiology Learning",
        image: heartbeatImg,
        component: Heartbeat,
    },
].map((project) => ({ url: stringToUrl(project.label), ...project }));

export default projects;

export const tagsInUse = projects.reduce((prev, cur) => {
    const curTags = cur.tags?.reduce(
        (prevTags, curTag) => ({ ...prevTags, [curTag]: tags[curTag] }),
        {}
    );
    return { ...prev, ...curTags };
}, {});
