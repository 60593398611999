import {
    PageHeader,
    ParagraphHeader,
    Paragraph,
} from "../../MainLayout/styled";
import { MainContainer, ContentText, Emp, MainImage } from "../styled";
import projectImg from "./castor.jpg";

const ProjectPage = () => {
    return (
        <MainContainer>
            <PageHeader>
                CASTOR &mdash; Visualizing Possible Future Landscapes
            </PageHeader>

            <MainImage src={projectImg} />

            <ContentText>
                <ParagraphHeader>Challenge</ParagraphHeader>
                <Paragraph>
                    What if you could step into the future and see how our
                    landscapes might evolve? That's exactly what CASTOR
                    (CAtchment Strategies TOwards Resilience), an NWO-ORC
                    project, aims to achieve.
                </Paragraph>
                <Paragraph>
                    Its goal is to protect and enhance the quality of sandy soil
                    landscapes in the Netherlands, balancing natural processes
                    and human interventions. Achieving this requires a long-term
                    systems perspective. However, despite the high quality of
                    scientific models, it remains difficult for stakeholders to
                    visualize what potential future landscapes could actually
                    look like. This is crucial, as decisions made today will
                    have lasting impacts.
                </Paragraph>

                <ParagraphHeader>Objective</ParagraphHeader>
                <Paragraph>
                    This project helps participants explore different futures
                    for a representative location: a field near Liessel in North
                    Brabant, situated next to the Astense Aa river.
                    Additionally, the VR experience is being used as an
                    educational tool at Wageningen University & Research (WUR).
                </Paragraph>

                <ParagraphHeader>The Result</ParagraphHeader>
                <Paragraph>
                    The WANDER XR Experience Lab developed an immersive VR
                    experience that transports users to current state and
                    possible future versions of the Liessel landscape. The
                    process involved:
                </Paragraph>
                <Paragraph as="ul" $keepTogether>
                    <li>Capturing a 360° photo of the current site.</li>
                    <li>
                        Creating three future scenarios through 3D modeling: a
                        food forest, a rewilded landscape, and an energy
                        landscape.
                    </li>
                    <li>
                        Rendering the scenarios in a photorealistic way,
                        complete with an environmental soundscape for maximum
                        immersion.
                    </li>
                    <li>
                        Basing these visualizations on advanced scientific
                        models from the Soil Geography and Landscape Group.
                    </li>
                </Paragraph>

                <ParagraphHeader>
                    Insights and Future Applications
                </ParagraphHeader>
                <Paragraph>
                    The experience was showcased at CASTOR workshops and beyond,
                    revealing key insights:
                </Paragraph>
                <Paragraph as="ul" $keepTogether>
                    <li>
                        <Emp>Diverse interpretations</Emp> - Stakeholders had
                        differing views on what &lsquo;food forest&rsquo;,
                        &lsquo;rewilding,&lsquo; and &lsquo;energy
                        landscape&lsquo; meant, making the visualization a
                        valuable tool for aligning perspectives.
                    </li>
                    <li>
                        <Emp>Educational impact</Emp> - Students responded
                        enthusiastically, noting that VR provided a far richer
                        learning experience than traditional PowerPoint
                        presentations. Since large field trips are often
                        impractical, VR offered an immersive and accessible
                        alternative.
                    </li>
                </Paragraph>
                <Paragraph>
                    The next phase of the project is already underway, with
                    potential enhancements including:
                </Paragraph>
                <Paragraph as="ul" $keepTogether>
                    <li>
                        <Emp>Animation and interactivity</Emp> to further enrich
                        the experience.
                    </li>
                    <li>
                        <Emp>Expanding the range of future scenarios</Emp> and
                        locations.
                    </li>
                    <li>
                        <Emp>Deeper integration into research</Emp> to study how
                        VR influences understanding of future landscapes.
                    </li>
                </Paragraph>
                <Paragraph>
                    This project has demonstrated the power of XR in making
                    scientific predictions tangible, bridging the gap between
                    research and real-world decision-making.
                </Paragraph>

                <ParagraphHeader>Collaborators</ParagraphHeader>
                <Paragraph>
                    This project was financed through an NWO-ORC grant. The
                    WANDER XR Experience Lab collaborated with dr. Simona Pedde
                    and dr. Jasper Candel from the Soil Geography and Landscape
                    group at WUR.
                </Paragraph>
            </ContentText>
        </MainContainer>
    );
};

export default ProjectPage;
